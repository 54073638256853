import React, { useEffect, useState } from "react";
import { fetchExamsByUser} from "../../services/api";
import { useAuthContext } from "../../context/AuthContext";

const ExamAccordion = () => {
    const { user } = useAuthContext(); 
    const [exams, setExams] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
  
    useEffect(() => {
      async function fetchData() {
        if (!user?.id) return;
    
        try {
          const results = await fetchExamsByUser(user.id);
          console.log("Resultados obtenidos:", results);
    
          const transformedExams = results
            .filter((result) => result?.attributes?.exam?.data?.attributes) 
            .map((result) => ({
              id: result.id,
              score: result.attributes.Score || "No disponible",
              date: result.attributes.createdAt || "Fecha no disponible",
              title: result.attributes.exam.data.attributes.title || "Sin título",
              description: result.attributes.exam.data.attributes.description || "Sin descripción",
            }));
          setExams(transformedExams);
        } catch (error) {
          console.error("Error fetching exams:", error);
        }
      }
    
      fetchData();
    }, [user?.id]);
    
  
    const toggleAccordion = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  
    return (
      <div className="space-y-4">
        {exams.length > 0 ? (
          exams.map((exam, index) => (
            <div key={exam.id} className="border rounded-lg shadow-md bg-white">
              <button
                onClick={() => toggleAccordion(index)}
                className="w-full text-left px-4 py-2 font-semibold text-gray-700 flex justify-between items-center"
              >
                <span>{exam.title}</span>
                <span
                  className={`transform ${
                    activeIndex === index ? "rotate-180" : "rotate-0"
                  }`}
                >
                  ▼
                </span>
              </button>
              {activeIndex === index && (
                <div className="px-4 py-2 bg-gray-50">
                  <p>
                    <strong>Descripción:</strong> {exam.description}
                  </p>
                  <p>
                    <strong>Fecha:</strong> {new Date(exam.date).toLocaleDateString()}
                  </p>
                  <p>
                    <strong>Nota:</strong> {exam.score}
                  </p>
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="text-gray-500">No tienes exámenes disponibles.</p>
        )}
      </div>
    );
  };
  

export default ExamAccordion;
