import React from "react";
import { MdCalculate } from "react-icons/md";
import { TbAbc } from "react-icons/tb";
import { ImLab } from "react-icons/im";

const IconComponents = {
  MdCalculate,
  TbAbc,
  ImLab,
};

export const QuickOptions = ({ options, onSelectOption }) => {
  return (
    <div className="flex flex-wrap justify-center gap-2">
      {options.map((option, index) => {
        const Icon = IconComponents[option.icon];
        return (
          <button
            key={index}
            onClick={() => onSelectOption(option.promp)}
            className="flex items-center px-2 py-1 bg-blue-50 text-gray-600 rounded-full shadow hover:bg-blue-200 hover:text-black"
          >
            <Icon className="mr-2" size={20} color={option.color} />
            {option.title}
          </button>
        );
      })}
    </div>
  );
};