import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import { envs } from "../../../config";
import { getToken } from "../../../helpers";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const ExamComponent = () => {
  const location = useLocation();
  const { exam } = location.state || {};
  const { user } = useAuthContext();
  const navigate = useNavigate();

  // Estado para almacenar el título y la descripción
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState(""); // Estado para la descripción

  if (!exam || !exam.questions) return <div>No hay examen disponible</div>;

  // Función para crear el examen en la base de datos
  const handleSubmit = async (e, redirectTo) => {
    e.preventDefault();

    const userId = user?.id || ""; // ID del usuario logueado
    if (!userId) {
      message.error("No se pudo obtener el ID del usuario.");
      return;
    }

    try {
      // Paso 1: Crear preguntas y opciones asociadas
      const createdQuestions = await Promise.all(
        exam.questions.map(async (question, index) => {
          console.log(`Creando pregunta ${index + 1}:`, question);

          // Crear la pregunta
          const questionResponse = await fetch(`${envs.api}/questions`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${envs.bearer} ${getToken()}`,
            },
            body: JSON.stringify({
              data: {
                questionNumber: question.questionNumber,
                questionText: question.questionText,
              },
            }),
          });

          if (!questionResponse.ok) {
            console.error(`Error creando la pregunta ${index + 1}:`, question);
            throw new Error("Error al crear la pregunta");
          }

          const questionResult = await questionResponse.json();
          console.log(`Pregunta creada:`, questionResult.data);

          // Crear las opciones asociadas a la pregunta
          const createdOptions = await Promise.all(
            question.options.map(async (option, optIndex) => {
              console.log(
                `Creando opción ${optIndex + 1} para la pregunta ID ${questionResult.data.id}:`,
                option
              );

              const optionResponse = await fetch(`${envs.api}/options`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `${envs.bearer} ${getToken()}`,
                },
                body: JSON.stringify({
                  data: {
                    question: questionResult.data.id,
                    text: option.text,
                    isCorrect: option.isCorrect,
                  },
                }),
              });

              if (!optionResponse.ok) {
                console.error(`Error creando la opción ${optIndex + 1}:`, option);
                throw new Error("Error al crear la opción");
              }

              const optionResult = await optionResponse.json();
              console.log(`Opción creada:`, optionResult.data);
              return optionResult.data;
            })
          );

          return {
            question: questionResult.data,
            options: createdOptions,
          };
        })
      );

      console.log("Preguntas y opciones creadas:", createdQuestions);

      // Paso 2: Crear respuestas asociadas a las preguntas creadas
      const createdAnswers = await Promise.all(
        exam.answers.map(async (answer, index) => {
          const associatedQuestion = createdQuestions.find(
            (q) => q.question.attributes.questionNumber === answer.questionNumber
          );

          if (!associatedQuestion) {
            console.error(
              `Pregunta no encontrada para el número de pregunta: ${answer.questionNumber}`
            );
            throw new Error("Error al asociar la respuesta con la pregunta");
          }

          console.log(
            `Creando respuesta ${index + 1} para la pregunta ID ${associatedQuestion.question.id}:`,
            answer
          );

          const answerResponse = await fetch(`${envs.api}/answers`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${envs.bearer} ${getToken()}`,
            },
            body: JSON.stringify({
              data: {
                question: associatedQuestion.question.id,
                correctOptionId: answer.correctOptionId,
                correctAnswer: answer.correctAnswer,
                questionNumber: answer.questionNumber,
              },
            }),
          });

          if (!answerResponse.ok) {
            const answerErrorData = await answerResponse.json();
            console.error(`Error creando la respuesta:`, answerErrorData.error || "Desconocido");
            throw new Error("Error al crear la respuesta");
          }

          const answerResult = await answerResponse.json();
          console.log(`Respuesta creada:`, answerResult.data);
          return answerResult.data;
        })
      );

      console.log("Respuestas creadas:", createdAnswers);

      // Paso 3: Crear el examen
      const examResponse = await fetch(`${envs.api}/exams`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${envs.bearer} ${getToken()}`,
        },
        body: JSON.stringify({
          data: {
            title,
            description,
            creator: userId,
            questions: createdQuestions.map((q) => q.question.id),
            answers: createdAnswers.map((a) => a.id),
          },
        }),
      });

      if (!examResponse.ok) {
        const examErrorData = await examResponse.json();
        console.error("Error al crear el examen:", examErrorData.error || "Desconocido");
        message.error(`Error al crear el examen: ${examErrorData.error?.message || "Desconocido"}`);
        return;
      }

      const examResult = await examResponse.json();
      console.log(`Examen creado:`, examResult.data);

      message.success("Examen creado exitosamente");

      // Redirigir según el botón clickeado, pasando el ID del examen creado
      navigate(redirectTo.replace(":id", examResult.data.id));

    } catch (error) {
      console.error("Error al crear el examen:", error);
      message.error(`Error: ${error.message}`);
    }
  };

  return (
    <div className="container mx-auto px-4 py-6 bg-white rounded-lg shadow-lg max-w-4xl">
      <h2 className="text-3xl font-semibold mb-6 text-center text-black-700">Previsualización del Examen</h2>

      {/* Formulario para ingresar el título del examen */}
      <div className="mb-6">
        <label htmlFor="examTitle" className="block font-semibold text-lg mb-2 text-gray-800">Dale un nombre a tu examen</label>
        <input
          type="text"
          id="examTitle"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Formulario para ingresar la descripción del examen */}
      <div className="mb-6">
        <label htmlFor="examDescription" className="block font-semibold text-lg mb-2 text-gray-800">Agrega una descripción</label>
        <textarea
          id="examDescription"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Renderiza las preguntas */}
      <div className="mb-6 space-y-8">
        {exam.questions.map((question, index) => (
          <div key={index} className="bg-gray-50 p-4 rounded-lg shadow-md border-l-4 border-blue-500">
            <div className="font-semibold text-lg text-gray-900 mb-2">{`Pregunta ${question.questionNumber}:`}</div>
            <div className="mb-4 text-gray-700">{question.questionText}</div>

            <div className="font-semibold text-gray-800 mb-2">Opciones:</div>
            <ul className="list-none pl-6 space-y-3">
              {question.options.map((option, i) => (
                <li key={i} className="text-gray-700 flex items-center">
                  <span className="font-semibold text-blue-600 mr-2">{`ABCDEF`[i]}:</span> {option.text}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Botón para crear el examen */}
      <div className="flex justify-center gap-3">
        <button
          onClick={(e) => handleSubmit(e, "/examen/:id/responder")}
          className="bg-blue-600 text-white py-3 px-6 rounded-lg text-lg hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300"
        >
          Presentar en línea
        </button>
        <button
          onClick={(e) => handleSubmit(e, "/examsTaken")}
          className="bg-blue-600 text-white py-3 px-6 rounded-lg text-lg hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300"
        >
          Ver más tarde
        </button>
      </div>
    </div>
  );
};

export default ExamComponent;
