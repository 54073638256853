import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchExamById } from "../../services/api";

export default function ExamDetail() {
  const { id } = useParams(); 
  const [exam, setExam] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadExam = async () => {
      try {
        const data = await fetchExamById(id); 
        console.log("Datos del examen:", data);
        setExam(data);
      } catch (err) {
        console.error("Error al cargar el examen:", err);
        setError("No se pudo cargar el examen.");
      } finally {
        setLoading(false);
      }
    };

    loadExam();
  }, [id]);

  if (loading) {
    return (
      <div className="p-6 h-screen bg-gray-100 rounded-lg shadow-lg flex items-center justify-center">
        <p className="text-gray-600">Cargando examen...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 h-screen bg-gray-100 rounded-lg shadow-lg flex items-center justify-center">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="p-6 h-screen bg-gray-100 rounded-lg shadow-lg">
      {exam?.attributes ? (
  <>
    <header className="mb-6">
      <h1 className="text-2xl font-bold text-gray-800">
        {exam.attributes.title}
      </h1>
      <p className="mt-2 text-gray-600">{exam.attributes.description}</p>
    </header>
    <section className="bg-white p-4 rounded-lg shadow-md">
      <p>
        <strong>Fecha de publicación:</strong>{" "}
        {new Date(exam.attributes.publishedAt).toLocaleDateString()}
      </p>
      {exam.attributes.creator?.data && (
        <div className="mt-4">
          <p>
            <strong>Creador:</strong>{" "}
            {exam.attributes.creator.data.attributes.name}
          </p>
          <p>
            <strong>Email:</strong>{" "}
            {exam.attributes.creator.data.attributes.email}
          </p>
        </div>
      )}
    </section>
  </>
) : (
  <p className="text-gray-600">No se encontraron detalles del examen.</p>
)}
     <div className="p-8 mt-8  text-center">
  <Link
   to={`/examen/${id}/responder`}
    className="inline-block bg-blue-500 text-white py-2 px-6 rounded-lg shadow-md hover:bg-blue-600 transition-colors"
  >
    Comenzar examen
  </Link>
</div>
</div>
  );
}
