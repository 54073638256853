import { Routes, Route, Navigate } from "react-router-dom";
import Profile from "./components/Profile/Profile";
import { getToken } from "./helpers";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import Home from "./components/Home/Home";
import History from "./components/History/History";
import { RecoverPassword } from "./pages/RecoverPassword/RecoverPassword";

import { Referred } from "./components";

import ExamComponent from "./components/Header/Test/ExamComponent";
import ExamProperties from "./components/Creator/ExamProperties";
import CreateExam from "./components/Creator/CreateExam";
import ExamDetail from "./components/ExamsTaken/ExamenDetail";
import ExamQuestions from "./components/ExamsTaken/ExamQuestions";
import ResultDetails from "./components/ExamsTaken/ResultDetails";
import AccountExamsTaken from "./components/ExamsTaken/accountExamsTaken";
import AccountCreator from "./components/Creator/accountCreator";


const isAuthenticated = () => !!getToken();

const ProtectedRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/signin" />;
};


const AppRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to={isAuthenticated() ? "/home" : "/signin"} />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />

        <Route path="/recoverpassword" element={<RecoverPassword />} />
        <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
        <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
        <Route path="/exam" element={<ProtectedRoute element={<ExamComponent />} />} />
        <Route path="/exam/:id" element={<ProtectedRoute element={<ExamDetail/>} />} />
        <Route path="/createExam" element={<ProtectedRoute element={<CreateExam />} />} />
        <Route path="/examen/:id/responder" element={<ProtectedRoute element={<ExamQuestions />} />} />
        <Route path="/resultado" element={<ProtectedRoute element={<ResultDetails />} />} />
        <Route path="/examsTaken" element={<ProtectedRoute element={<AccountExamsTaken />} />} />
        <Route path="/creator" element={<ProtectedRoute element={<AccountCreator />} />} />
        <Route path="/exam/student" element={<ProtectedRoute element={<ExamProperties />} />} />
        <Route path="/history/:threadId?" element={<ProtectedRoute element={<History />} />} />
        <Route path="/referred" element={<ProtectedRoute element={<Referred />} />} />

      </Routes>
    </div>
  );
};

export default AppRoutes;
