import React from "react";
import { Link } from "react-router-dom";

export const RecoverPassword = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen bg-gray-100 px-10">
      <div className="bg-white shadow-md rounded-lg p-3 w-full max-w-md">
        <h1 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
          Recupera tu cuenta
        </h1>
        <p className="text-sm text-gray-600 mb-6 text-center">
          Ingresa tu correo electrónico o número de celular para buscar tu
          cuenta.
        </p>
        <div className="flex flex-col gap-4">
          <input
            type="text"
            placeholder="Correo o número de celular"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
          <div className="flex justify-between gap-4">
            <button className="w-full bg-gray-300 hover:bg-gray-400 text-gray-800 font-medium py-2 rounded-md transition-all">
              <Link to={"/signin"}>Cancelar</Link>
            </button>
            <button className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 rounded-md transition-all">
              Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
