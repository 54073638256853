export const Referred = () => {
  return (
    <div className="flex items-center justify-center h-screen overflow-auto">
      <div className="bg-white p-8 rounded-xl shadow-lg max-w-6xl w-full mx-4 items-center justify-center">
        <div className="text-center mb-6">
          <h1 className="text-2xl font-bold text-blue-600">
            ¡Oportunidad Increíble!
          </h1>
          <p className="text-gray-900 mt-2">
            Refiere a un amigo o compañero interesado en{" "}
            <span className="font-semibold">Edtools</span> y obtén una
            bonificación en tu suscripción.
          </p>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between gap-6">
          <div className="flex-1 text-center md:text-left">
            <p className="text-gray-600 mb-4">
              Comparte esta oportunidad por correo electrónico y ayúdales a
              descubrir la innovación en{" "}
              <span className="font-semibold">
                pruebas automatizadas y exámenes con IA.
              </span>
            </p>
            <div className="flex items-center gap-2">
              <input
                type="email"
                placeholder="Introduce el correo electrónico"
                className="flex-1 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition">
                Enviar
              </button>
            </div>
          </div>
          <div className="relative flex-1 max-w-sm mx-auto md:mx-0">
            <img
              src="/imagen-3.svg"
              alt="Estudiantes"
              className="w-full rounded-xl"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gray-700/30 via-transparent to-transparent rounded-xl"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
