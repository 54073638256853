import React, { useEffect, useState } from "react";
import "./index.css";
import AppRoutes from "./Routes";
import { Sidebar, Navbar, SidebarContent, Loading } from "./components";
import { useAuthContext } from "./context/AuthContext";
import Clarity from '@microsoft/clarity';

const projectId = "po8dcc19h5"
Clarity.init(projectId);

const App = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedContent, setSelectedContent] = useState("home");
  const { user, isLoading } = useAuthContext();

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 768); // Sidebar abierto por defecto en pantallas grandes
    };

    handleResize(); // Ajustar al cargar la página
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev); // Alternar el estado del sidebar
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-gray-500 dark:text-gray-300">
          <Loading />
        </p>
      </div>
    );
  }

  return (
    <div className="flex">
      {user && (
        <>
          <Sidebar
            isOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
            setSelectedContent={setSelectedContent}
            selectedContent={selectedContent}
          />
          {isSidebarOpen && (
            <SidebarContent
              selectedContent={selectedContent}
              className="fixed top-0 left-1 w-full sm:w-64 z-50"
            />
          )}
        </>
      )}
      <div
        className={`flex-1 flex flex-col ${
          isSidebarOpen && window.innerWidth < 768 ? "hidden" : "block"
        }`}
      >
        <Navbar isSidebarOpen={isSidebarOpen} />
        <div className="w-full h-full bg-white">
          <AppRoutes />
        </div>
      </div>
    </div>
  );
};

export default App;
