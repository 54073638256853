import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Spin,
  Select,
  DatePicker
} from "antd";
import dayjs from "dayjs";
import { useAuthContext } from "../../context/AuthContext";
import {
  fetchCodeCountries,
  fetchOccupations,
  updateUserProfile,
} from "../../services/api";
import { envs } from "../../config";
import { getToken } from "../../helpers";

const { Option } = Select;

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [occupations, setOccupations] = useState([]);
  const [codeCountries, setCodeCountries] = useState([]);
  const { user, isLoading, setUser } = useAuthContext();

  const fetchUserData = useCallback(async () => {
    if (user?.id) {
      try {
        const response = await fetch(`${envs.api}/users/${user.id}?populate=*`, {
          headers: { Authorization: `Bearer ${getToken()}` },
        });
        const data = await response.json();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  }, [user?.id, setUser]);

  const loadOccupations = useCallback(async () => {
    try {
      const list = await fetchOccupations(); 
      setOccupations(list.map((item) => ({ id: item.id, ...item.attributes })));
    } catch (error) {
      console.error("Error loading occupations:", error);
    }
  }, []);
  

  const loadCodeCountries = useCallback(async () => {
    try {
      const list = await fetchCodeCountries();
      setCodeCountries(list.map((item) => ({ id: item.id, ...item.attributes })));
    } catch (error) {
      console.error("Error loading code countries:", error);
    }
  }, []);
  

  useEffect(() => {
    fetchUserData();
    loadOccupations();
    loadCodeCountries();
  }, [fetchUserData, loadOccupations, loadCodeCountries]);

  const handleProfileUpdate = async (data) => {
    setLoading(true);
    try {
      // Solo actualiza el cumpleaños si ha cambiado
      if (data.birthdaydate) {
        data.birthdaydate = dayjs(data.birthdaydate).format("YYYY-MM-DD");
      }
      
      // Verifica si el campo occupation ha cambiado antes de enviarlo
      const updatedData = {};
      if (data.birthdaydate !== user.birthdaydate) updatedData.birthdaydate = data.birthdaydate;
      if (data.occupation !== user.occupation?.id) updatedData.occupation = data.occupation;
      if (data.code_country !== user.code_country?.id) updatedData.code_country = data.code_country;
  
      if (Object.keys(updatedData).length > 0) {
        const responseData = await updateUserProfile(user.id, updatedData);
        setUser(responseData);
        message.success("Data saved successfully!");
        await fetchUserData(); // Refresh user data after update
      } else {
        message.info("No changes detected to update.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      message.error("Error while updating the profile!");
    } finally {
      setLoading(false);
    }
  };
  
  if (isLoading || !user) {
    return <Spin size="large" />;
  }

  return (
    <Card className="profile_page_card p-6">
    <Form
      layout="vertical"
      initialValues={{
        username: user?.username,
        email: user?.email,
        name: user?.name || "",
        lastname: user?.lastname || "",
        code_country: user?.code_country?.id || undefined,
        company: user?.company || "",
        occupation: user?.occupation?.id || undefined,
        phone: user?.phone || "",
        about: user?.about || "",
        birthdaydate: user.birthdaydate ? dayjs(user.birthdaydate) : null,
      }}
      onFinish={handleProfileUpdate}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
  
        {/* Username and Email (Always on top, in one column) */}
        <div className="col-span-1">
          <Form.Item
            label="Usuario"
            name="username"
            rules={[{ required: true, message: "Username es requerido!" }]}
          >
            <Input placeholder="Username" disabled />
          </Form.Item>
        </div>
  
        <div className="col-span-1">
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Email is required!", type: "email" },
            ]}
          >
            <Input placeholder="Email" disabled />
          </Form.Item>
        </div>
  
        {/* About (TextArea, No Change) */}
      
        <div className="col-span-1 md:col-span-2 lg:col-span-3">
          <Form.Item
            label="A qué te dedicas?"
            name="about"
            rules={[{ type: "string" }]}
          >
            <Input.TextArea placeholder="Cuéntanos si tienes una profesión o si estás estudiando alguna carrera o área en particular" rows={3} />
          </Form.Item>
        </div>
  
        {/* Name, Lastname, and Phone (Row 1) */}
        <div className="col-span-1">
          <Form.Item
            label="Nombres"
            name="name"
            rules={[{ type: "string", required: true }]}
          >
            <Input placeholder="Nombre" />
          </Form.Item>
        </div>
  
        <div className="col-span-1">
          <Form.Item
            label="Apellidos"
            name="lastname"
            rules={[{ type: "string", required: true }]}
          >
            <Input placeholder="Apellido" />
          </Form.Item>
        </div>
  
        <div className="col-span-1">
          <Form.Item label="Telefono sin el código" name="phone">
            <Input placeholder="Teléfono" />
          </Form.Item>
        </div>
  
        {/* Code Country and Company (Row 2) */}
        <div className="col-span-1">
        <Form.Item
            label="Fecha de Nacimiento"
            name="birthdaydate"
            rules={[{ required: true }]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          
        </div>
  
        {/* Change Country Code (Row 3) */}
        <div className="col-span-1">
        <Form.Item label="Código de país para tu teléfono">
            <Input
              value={user?.code_country?.value || "Selecciona tu código de país"}
              disabled
              placeholder="Código de país"
            />
          </Form.Item>
            </div>

        <div className="col-span-1">
          <Form.Item label="Cambiar código de país" name="code_country">
            <Select placeholder="Selecciona tu código de país">
              {codeCountries.map((codeCountry) => (
                <Option key={codeCountry.id} value={codeCountry.id}>
                  {codeCountry.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
  
        <div className="col-span-1">
          <Form.Item label="Compañía" name="company">
            <Input placeholder="Compañía" />
          </Form.Item>
        </div>
  
        {/* Occupation and Change Occupation (Row 4) */}
        <div className="col-span-1">
          <Form.Item label="Ocupación">
            <Input
              value={user?.occupation?.value || "Ingresa tu ocupación"}
              disabled
              placeholder="Ocupación"
            />
          </Form.Item>
        </div>
  
        <div className="col-span-1">
          <Form.Item label="Cambiar ocupación" name="occupation">
            <Select placeholder="Selecciona tu ocupación">
              {occupations.map((occupation) => (
                <Option key={occupation.id} value={occupation.id}>
                  {occupation.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
       
      </div>
  
      <Button
        className="bg-[#00DB8E] rounded-md px-4 py-3 w-80 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
        htmlType="submit"
        type="primary"
        size="large"
      >
        {loading ? (
          <>
            <Spin size="small" /> Saving
          </>
        ) : (
          "Guardar Cambios"
        )}
      </Button>
    </Form>
  </Card>
  );
};

export default Profile;
