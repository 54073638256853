import React from 'react';
import { useNavigate } from 'react-router-dom';
import ExamIa from './ExamIa';

export default function CreateExam() {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Examen enviado");
    navigate("/teacher");
  };

  return (
    <div className="max-w-3xl mx-auto p-8 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-center mb-4 text-gray-700">
        Crear Examen
      </h2>
      <p className="text-sm text-gray-600 mb-6 text-center">
        Completa los detalles de tu examen. Al hacer clic en "Guardar", serás redirigido a tu panel. 
      </p>
      <form onSubmit={handleSubmit} className="space-y-6">
  <div>
    <label htmlFor="examTitle" className="block text-sm font-medium text-gray-700 mb-2">
      Título del examen
    </label>
    <input
      id="examTitle"
      name="examTitle"
      type="text"
      className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-sleate-900"
      placeholder="Ejemplo: Historia Universal"
      required
    />
  </div>

  <div>
    <label htmlFor="examDescription" className="block text-sm font-medium text-gray-700 mb-2">
      Breve descripción
    </label>
    <textarea
      id="examDescription"
      name="examDescription"
      rows="3"
      className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-sleate-900 resize-none"
      placeholder="Ejemplo: Examen sobre eventos importantes de la historia."
      required
    />
  </div>

  {/* Nuevo input para seleccionar grado */}
  <div>
    <label htmlFor="grade" className="block text-sm font-medium text-gray-700 mb-2">
      Grado
    </label>
    <select
      id="grade"
      name="grade"
      className="w-full p-2 border text-gray-700 border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-sleate-900"
      required
    >
      <option value="" disabled selected className='text-gray-700'>Selecciona el grado</option>
      <option value="primerGrado" className='text-gray-700'>Primer Grado</option>
      <option value="quintoGrado" className='text-gray-700'>Quinto Grado</option>
    </select>
  </div>
        <ExamIa />

        <button
          type="submit"
          className="w-full bg-slate-900 text-white py-2 px-4 rounded-md hover:bg-slate-400 transition duration-300 ease-in-out"
        >
          Crear Examen
        </button>
      </form>
    </div>
  );
}
