import { CopyButton } from "./CopyButton";

export const CodeBlock = ({ content }) => {
  return (
    <div className="relative bg-gray-800 my-4 text-white p-5 rounded-xl shadow-lg">
      <div className="absolute top-2 right-2">
        <CopyButton textToCopy={content} />
      </div>
      <pre className="overflow-x-auto whitespace-pre-wrap break-words">
        <code>{content}</code>
      </pre>
    </div>
  );
};
