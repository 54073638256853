import React, { useState } from "react";

const ExamAccordionTeacher = ({ exams }) => {
  const [examList, setExamList] = useState(exams);
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const toggleStatus = (index) => {
    const updatedExams = [...examList];
    updatedExams[index].status =
      updatedExams[index].status === "Abierto" ? "Finalizado" : "Abierto";
    setExamList(updatedExams);
  };

  return (
    <div className="space-y-4">
      {examList.map((exam, index) => (
        <div key={index} className="border rounded-lg shadow-md bg-white">
          {/* Título del acordeón */}
          <button
            onClick={() => toggleAccordion(index)}
            className="w-full text-left px-4 py-2 font-semibold text-gray-700 flex justify-between items-center"
          >
            <span>{exam.title}</span>
            <span
              className={`transform ${
                activeIndex === index ? "rotate-180" : "rotate-0"
              }`}
            >
              ▼
            </span>
          </button>

          {/* Contenido del acordeón */}
          {activeIndex === index && (
            <div className="px-4 py-2 bg-gray-50">
              <p>
                <strong>Estado:</strong> {exam.status}
              </p>
              <p>
                <strong>Fecha de finalización:</strong> {exam.date}
              </p>
              <p>
                <strong>Promedio:</strong> {exam.promedio}
              </p>
              <p>
                <strong>Clase:</strong> {exam.class}
              </p>
              <button
                  onClick={() => toggleStatus(index)}
                  className={`mt-3 px-4 py-2 rounded-lg transition ${
                    exam.status === "Abierto"
                    ? "bg-red-500 hover:bg-red-700"
                    : "bg-slate-800 hover:bg-slate-400"
                  } text-white`}
                >
                  {" "}
                  {exam.status === "Abierto" ? "Finalizar exámen" : "Habilitar exámen"}
                </button>

            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ExamAccordionTeacher;
