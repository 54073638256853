export const parseExam = (text) => {
  if (typeof text !== "string") {
    throw new Error("El texto proporcionado no es una cadena de caracteres");
  }


  text = text
    .replace(/[“”]/g, '"') // Cambia comillas estilizadas por comillas estándar
    .replace(/\[Opción múltiple\]\s*/g, "[Opción múltiple]") // Elimina espacios extra después de [Opción múltiple]
    .replace(/\s+/g, " ") // Reduce múltiples espacios a uno solo
    .trim(); // Elimina espacios al inicio y al final



  const questions = [];
  const answers = [];

  // Regex para identificar preguntas y sus bloques de opciones
  const questionRegex =
    /(\d+)\.\s*¿([^?]+)\?\s*:\s*([\s\S]+?)(?=\d+\.\s*|$)/g; // Esta regex captura todas las preguntas

  // Regex para identificar cada opción dentro de un bloque
  const optionRegex =
    /\[Opción múltiple\]\s*(.*?)\s+-\s+\[Opción de respuesta \d+ es correcta "(sí|no|si)"\]/g;

  let match;

  // Parsear preguntas
  while ((match = questionRegex.exec(text)) !== null) {

    const questionNumber = parseInt(match[1], 10);
    const questionText = match[2].trim();
    const optionsBlock = match[3].trim();

    const options = [];
    let correctAnswer = null;

    let optionMatch;

    // Parsear opciones dentro del bloque de opciones
    while ((optionMatch = optionRegex.exec(optionsBlock)) !== null) {
      console.log("Opción encontrada:", optionMatch);

      const optionText = optionMatch[1].trim();
      const isCorrect = optionMatch[2].toLowerCase() === "si";

      options.push({ text: optionText, isCorrect });

      if (isCorrect) {
        correctAnswer = optionText;
      }
    }

    if (options.length > 0) {
      questions.push({ questionNumber, questionText, options });
      answers.push({ questionNumber, correctAnswer });
    }
  }

  return { questions, answers };
};
