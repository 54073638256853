import React, { useState, useEffect } from 'react';
import { Button, message, Spin } from 'antd';
import OpenAI from 'openai';

export const Message = ({ selectedThreadId }) => {
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);

  const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_KEY, dangerouslyAllowBrowser: true });

  // Cargar mensajes iniciales al seleccionar un thread
  useEffect(() => {
    if (!selectedThreadId) return;

    const loadMessages = async () => {
      setLoadingMessages(true);
      setMessages([]); // Limpiar mensajes previos
      setHasMoreMessages(true); // Resetear estado de carga adicional

      try {
        const threadMessages = await openai.beta.threads.messages.list(selectedThreadId, { limit: 10 });
        setMessages(threadMessages.data);

        if (threadMessages.data.length < 10) {
          setHasMoreMessages(false); // No hay más mensajes que cargar
        }
      } catch (error) {
        message.error(`Error al cargar mensajes: ${error.message}`);
      } finally {
        setLoadingMessages(false);
      }
    };

    loadMessages();
  }, [selectedThreadId]);

  // Cargar más mensajes
  const loadMoreMessages = async () => {
    if (!selectedThreadId || loadingMessages || !hasMoreMessages) return;

    setLoadingMessages(true);

    try {
      const lastMessageId = messages[messages.length - 1]?.id; // Obtener el ID del último mensaje cargado
      const newMessages = await openai.beta.threads.messages.list(selectedThreadId, { limit: 10, after: lastMessageId });

      setMessages((prevMessages) => [...prevMessages, ...newMessages.data]); // Añadir nuevos mensajes al estado

      if (newMessages.data.length < 10) {
        setHasMoreMessages(false); // Desactivar botón si no hay más mensajes
      }
    } catch (error) {
      message.error(`Error al cargar más mensajes: ${error.message}`);
    } finally {
      setLoadingMessages(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-8">
      <h1 className="font-semibold text-2xl text-gray-800 mt-8 mb-6 p-4 rounded-md">
        Mensajes
      </h1>

      {loadingMessages && messages.length === 0 && <Spin tip="Cargando mensajes..." />} {/* Mostrar spinner solo al inicio */}

      {selectedThreadId && messages.length > 0 ? (
        <div className="text-sm">
          {messages.map((msg, index) => (
            <div key={index}>
              <p>{msg.role}: {msg.content[0]?.text?.value || ''}</p> {/* Ajusta esto según el formato de los mensajes */}
            </div>
          ))}
        </div>
      ) : (
        <div className="mt-12">No hay mensajes disponibles.</div>
      )}

      {hasMoreMessages && !loadingMessages && (
        <Button onClick={loadMoreMessages} className="mt-5">
          Cargar más mensajes
        </Button>
      )}

      {loadingMessages && messages.length > 0 && (
        <Spin tip="Cargando más mensajes..." className="mt-5" /> 
      )}
    </div>
  );
};
