import { HomeContent } from "../../content/HomeContent/HomeContent";
import { MessagesContent } from "../../content/MessagesContent/MessagesContent";
import Buttons from "../../Creator/Buttons";



export const SidebarContent = ({ selectedContent }) => {
  const renderContent = () => {
    switch (selectedContent) {
      case "home":
        return <HomeContent />;
      case "messages":
        return <MessagesContent />;
       case "buttons":
         return <Buttons />;
      // case "createExam":
      //   return <KanbanContent />;
      // case "settings":
      //   return <SettingsContent />;
      default:
        return <HomeContent />;
    }
  };

  return (

    <div className="min-h-screen px-5 py-8 overflow-y-auto border-l border-r sm:w-64 bg-stone-900 border-stone-700">
      {renderContent()}
    </div>

  );
};