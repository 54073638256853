import React from "react";
import ReactMarkdown from "react-markdown";
import { CodeBlock } from "./CodeBlock";

export const Message = ({ text, sender }) => {
  const isUser = sender === "user";

  const renderers = {
    code: ({ inline, children }) => {
      const content = typeof children === "string" ? children : "";
      return inline ? (
        <code className="bg-gray-200 text-red-600 px-1 rounded">{content}</code>
      ) : (
        <CodeBlock content={content} />
      );
    },
  };

  const markdownText = typeof text === "string" ? text : "";

  return (
    <div
      className={`flex ${
        isUser ? "justify-end" : "justify-start"
      } animate-fade-in`}
    >
      <div
        className={`max-w-[75%] px-4 py-3 rounded-lg text-sm shadow-lg transition-all duration-300 ${
          isUser
            ? "bg-blue-500 text-white rounded-br-none"
            : "bg-gray-200 text-gray-800 rounded-bl-none"
        } overflow-auto break-words`}
      >
        <ReactMarkdown components={renderers}>{markdownText}</ReactMarkdown>
      </div>
    </div>
  );
};
