import React, { useState } from "react";
import { mockExamData } from "../Header/Test/mockExamData";

const ExamIa = () => {
  const [userAnswers, setUserAnswers] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [score, setScore] = useState(null); // Puntaje del usuario
 

  const handleAnswer = (questionId, answerIndex) => {
    setUserAnswers((prev) => {
      const currentAnswers = prev[questionId] || [];
      if (currentAnswers.includes(answerIndex)) {
        return {
          ...prev,
          [questionId]: currentAnswers.filter((answer) => answer !== answerIndex),
        };
      } else {
        return {
          ...prev,
          [questionId]: [...currentAnswers, answerIndex],
        };
      }
    });
  };

  const validateAnswers = () => {
    for (const question of mockExamData.questions) {
      if (!userAnswers[question.id] || userAnswers[question.id].length === 0) {
        return false;
      }
    }
    return true;
  };

  const calculateScore = () => {
    let correctCount = 0;

    for (const question of mockExamData.questions) {
      const userSelected = userAnswers[question.id] || [];
      const correctAnswers = question.correctAnswers;

      // Verifica si las respuestas del usuario coinciden exactamente con las correctas
      if (
        userSelected.length === correctAnswers.length &&
        userSelected.every((ans) => correctAnswers.includes(ans))
      ) {
        correctCount++;
      }
    }

    // Calcula el porcentaje
    const totalQuestions = mockExamData.questions.length;
    const percentage = Math.round((correctCount / totalQuestions) * 100);

    return percentage;
  };

  const handleSubmit = () => {
    if (!validateAnswers()) {
      setError("Por favor, selecciona al menos una opción para cada pregunta.");
      return;
    }

    setError("");
    const finalScore = calculateScore();
    setScore(finalScore);
    setIsSubmitted(true);
  };

  const resetExam = () => {
    setUserAnswers({});
    setIsSubmitted(false);
    setScore(null);
    setError("");
  };

  return (
    <div className="flex flex-col justify-center text-center mt-4">
      {mockExamData.questions.map((question, questionIndex) => (
        <div className="border hover:cursor-pointer p-4" key={question.id}>
          {/* Mostrar número de pregunta */}
          <h2 className="mt-4 font-semibold">
            {questionIndex + 1}. {question.question}
          </h2>
          <ul>
            {question.options.map((option, optionIndex) => (
              <li key={optionIndex} className="flex items-center justify-center gap-2">
                {!isSubmitted ? (
                  <>
                    <input
                      type="checkbox"
                      id={`question-${question.id}-option-${optionIndex}`}
                      checked={userAnswers[question.id]?.includes(optionIndex) || false}
                      onChange={() => handleAnswer(question.id, optionIndex)}
                    />
                    {/* Mostrar letra (a, b, c, d) antes de cada opción */}
                    <label htmlFor={`question-${question.id}-option-${optionIndex}`}>
                      {String.fromCharCode(97 + optionIndex)}. {option}
                    </label>
                  </>
                ) : (
                  <div
                    className={`p-1 rounded ${
                      question.correctAnswers.includes(optionIndex)
                        ? "bg-green-200"
                        : userAnswers[question.id]?.includes(optionIndex)
                        ? "bg-red-200"
                        : ""
                    }`}
                  >
                    {option}{" "}
                    {question.correctAnswers.includes(optionIndex) && "(Correcta)"}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
      {error && <p className="text-red-500 mt-4">{error}</p>}
      {!isSubmitted ? (
        <div className="flex justify-center">
       
        </div>
      ) : (
        <div className="mt-4 flex justify-end mr-8">
          <div className="flex flex-col text-center">
            <h2 className="font-bold">Tu puntaje: {score}%</h2>
            <p>{score >= 70 ? "¡Aprobaste!" : "No aprobaste, sigue intentando."}</p>
            <button
              className="bg-yellow-300 w-auto text-black mt-4 px-4 py-2 rounded hover:bg-yellow-400"
              onClick={resetExam}
            >
              Volver a realizar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamIa;