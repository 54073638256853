import React from 'react'
import { useAuthContext } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import ExamAccordionTeacher from './ExamAccordionTeacher';
import ClassAccordion from './ClassAccordion';


    export default function AccountCreator() {
        const { user } = useAuthContext();
      
        const completedExams = [
          { title: "Examen 1", status: "Finalizado", date: "20/12/2024", promedio: "8.5", class: "Primer Grado" },
          { title: "Examen 2", status: "Abierto", date: "18/12/2024", promedio: "Pendiente", class: "Quinto Grado" },
          { title: "Examen 3", status: "Abierto", date: "15/12/2024", promedio: "Pendiente", class: "Primer Grado" },
        ];
      
        const pendingExams = [
          { title: "Historia Universal", dueDate: "20/12/2024" },
          { title: "Examen 5", dueDate: "25/12/2024" },
        ];
      
        return (
          <div className="p-6 min-h-screen bg-gray-100 rounded-lg shadow-lg">
            <header className="mb-6">
              <h1 className="text-2xl font-bold text-gray-800 uppercase">Hola, {user?.name}</h1>
              <p className="mt-2 text-gray-600">
                Aquí puedes consultar los exámenes completados por tus estudiantes y habilitar nuevos exámenes creados.
              </p>
            </header>
            <section className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Sección de Exámenes Realizados */}
              <div className="p-4 bg-white rounded-lg shadow-md">
                <h2 className="text-lg font-semibold text-gray-700 mb-4">Exámenes creados</h2>
                <ExamAccordionTeacher exams={completedExams} />
              </div>
      
{/* Sección de Exámenes Pendientes */}
<div className="p-4 bg-white rounded-lg shadow-md">
  <h2 className="text-lg font-semibold text-gray-700 mb-4">Resultados por estudiantes</h2>
  <ul className="text-gray-600 grid grid-cols-1 gap-4">
    {pendingExams.map((exam) => (
      <li
        key={exam.id}
        className="p-3 bg-gray-50 rounded-lg shadow-sm hover:bg-gray-100 transition-all duration-200 flex flex-col"
      >
        <Link
          to={`/exam/student`} /* Cambiar a `/exam/${exam.id}` cuando sea dinámico */
          className="text-blue-600 hover:text-blue-800 font-medium"
        >
          <p>{exam.title}</p>
          <p className="text-sm text-gray-500">Fecha límite: {exam.dueDate}</p>
        </Link>
      </li>
    ))}
  </ul>
</div>


                {/* Sección de clases */}
                <div className="p-4 bg-white rounded-lg shadow-md">
                <h2 className="text-lg font-semibold text-gray-700 mb-4">Clases o Grados</h2>
                <ClassAccordion exams={completedExams} />
                <button className='mt-4 bg-slate-900 text-white p-2 rounded hover:bg-slate-700'>Crear clase</button>
              </div>
            </section>
          </div>
        );
      }