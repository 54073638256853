import React, { useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { Message, InputBox, QuickOptions, TypingIndicator } from "./components";
import { useChat } from "../../hooks/useChat";
import { useNavigate } from "react-router-dom";

export const ChatBox = () => {
  const ASSISTANT_ID = process.env.REACT_APP_ASSISTANT_ID;
  const { user } = useAuthContext();
  const [showExam, setShowExam] = useState(false);
  const navigate = useNavigate();

  const {
    messages,
    inputValue,
    isLoading,
    isTyping,
    isFirstMessage,
    messagesEndRef,
    handleChange,
    handleSend,
    setInputValue,
    setIsFirstMessage,
    exam,
  } = useChat(ASSISTANT_ID, user);




  const quickOptions = [
    {
      title: "Matemáticas",
      icon: "MdCalculate",
      promp: "Quiero hacer un examen de matemáticas.",
      color: "orange",
    },
    {
      title: "Literatura",
      icon: "TbAbc",
      promp: "Quiero hacer un examen de literatura.",
      color: "black",
    },
    {
      title: "Física",
      icon: "ImLab",
      promp: "Quiero hacer un examen de física.",
      color: "red",
    },
  ];

  const handleSelectOption = (text) => {
    setInputValue(text);
    setIsFirstMessage(false);
  };

  const handleShowExam = () => {

    navigate("/exam", { state: { exam } });
  };
  


  return (
    <div className="flex flex-col h-full bg-white">
      <div
        className="flex-1 overflow-y-auto overflow-x-hidden p-4 rounded-lg space-y-4 pb-20"
        style={{ maxHeight: "85vh" }}
      >
        {messages.map((msg) => (
          <Message
            key={msg.id}
            text={msg.text}
            sender={msg.sender}
            showExamButton={exam && !showExam && msg.sender === "assistant"}
            onShowExam={handleShowExam}
          />
        ))}


        {/* Botón para redirigir a ExamComponent */}
        {exam && !showExam && (
          <button
            onClick={handleShowExam}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg mt-4"
          >
            Ver examen en línea
          </button>
        )}


        {isTyping && <TypingIndicator />}
        <div ref={messagesEndRef} />
      </div>

      <div
        className={`transition-all duration-500 w-full max-w-lg flex flex-col items-center rounded-lg  ${
          isFirstMessage
            ? "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            : "fixed bottom-4 left-1/2 transform -translate-x-1/2"
        }`}
        style={{ maxHeight: "15vh" }}
      >
        {isFirstMessage && (
          <h1 className="text-center text-lg font-semibold mb-4">
            ¿Qué prueba quieres crear hoy?
          </h1>
        )}
        <InputBox
          value={inputValue}
          onChange={handleChange}
          onSend={handleSend}
          isLoading={isLoading}
        />
        {isFirstMessage && (
          <QuickOptions
            options={quickOptions}
            onSelectOption={handleSelectOption}
          />
        )}
      </div>
    </div>
  );
};
