import React, { useState } from "react";
import { mockClassData } from "./MockClass";

const ClassAccordionTeacher = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="space-y-4">
      {mockClassData.map((classItem, index) => (
        <div key={index} className="border rounded-lg shadow-md bg-white">
          {/* Título del acordeón */}
          <button
            onClick={() => toggleAccordion(index)}
            className="w-full text-left px-4 py-2 font-semibold text-gray-700 flex justify-between items-center"
          >
            <span>{classItem.name}</span>
            <span
              className={`transform ${activeIndex === index ? "rotate-180" : "rotate-0"}`}
            >
              ▼
            </span>
          </button>

          {/* Contenido del acordeón */}
          {activeIndex === index && (
            <div className="px-4 py-2 bg-gray-50">
              <p><strong>Estudiantes:</strong> {classItem.students.length}</p>
              <ul>
                {classItem.students.map((student, idx) => (
                  <li key={idx} className="p-2">
                    <strong>{student.name}</strong> - {student.email}
                  </li>
                ))}
              </ul>
              <button className="mt-3 px-4 py-2 bg-slate-700 text-white rounded-lg hover:bg-slate-400 transition">
                Ver Clase
              </button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ClassAccordionTeacher;
