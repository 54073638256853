import React from 'react';
import { useAuthContext } from '../../context/AuthContext';

export default function ExamProperties() {
  const { user } = useAuthContext();

  // Mock de datos de un examen con estudiantes
  const examMock = {
    title: "Examen de Historia Universal",
    createdAt: "19/12/2024",
    date: "20/12/2024",
    promedio: 7,
    students: [
      { name: "Juan", lastname: "Pérez", email: "juan.perez@example.com", nota: 8.5 },
      { name: "Ana", lastname: "García", email: "ana.garcia@example.com", nota: 7.2 },
      { name: "Luis", lastname: "Martínez", email: "luis.martinez@example.com", nota: 9.0 },
    ],
  };

  return (
    <div className="p-6 h-screen bg-gray-100 rounded-lg shadow-lg">
      <header className="mb-6">
        <h1 className="text-2xl font-bold text-gray-800 uppercase">
          Hola, {user?.name}
        </h1>
        <p className="mt-2 text-gray-600">
          Aquí puedes consultar los resultados del examen por estudiantes
        </p>
      </header>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Sección de Exámenes */}
        <div className="p-4 bg-white rounded-lg shadow-md">
          <h2 className="text-lg font-semibold text-gray-700 mb-4">{examMock.title}</h2>
          <p className="text-gray-600 mb-2">
            <strong>Fecha de inicio:</strong> {examMock.createdAt}
          </p>
           <p className="text-gray-600 mb-2">
            <strong>Fecha de finalización:</strong> {examMock.date}
          </p>
          <ul className="space-y-2">
            {examMock.students.map((student, index) => (
              <li
                key={index}
                className="p-2 border rounded-lg bg-gray-50 shadow-sm"
              >
                <p>
                  <strong>Nombre:</strong> {student.name} {student.lastname}
                </p>
                <p>
                  <strong>Email:</strong> {student.email}
                </p>
                <p>
                  <strong>Nota:</strong> {student.nota}
                </p>
              </li>
             
            ))}
          </ul>
          <p className='p-4 text-center font-bold rounded bg-blue-500 text-white'>Promedio: {examMock.promedio}</p>
        </div>
      </section>
    </div>
  );
}
