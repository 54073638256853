import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa6";

export const CopyButton = ({ textToCopy }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  return (
    <div className="">
      <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
        <button
          className="flex items-center bg-slate-200 text-xs font-semibold p-0.5 m-1 text-gray-800 rounded-lg hover:text-slate-200
        sm:text-sm"
        >
          {copied ? "¡Examen Copiado!" : "Copiar Examen"}
          <FaRegCopy size={15} className="ml-1 sm:ml-2" />
        </button>
      </CopyToClipboard>
    </div>
  );
};
