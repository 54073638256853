// mockExamData.js
export const mockExamData = {
    examTitle: "Historia Universal",
    questions: [
      {
        id: 1,
        question: "¿Quién descubrió América?",
        options: ["Cristóbal Colón", "Marco Polo", "Américo Vespucio", "Vasco da Gama"],
        correctAnswers: [0],
      },
      {
        id: 2,
        question: "¿En qué año cayó el Imperio Romano?",
        options: ["476", "1492", "1066", "1789"],
        correctAnswers: [3],
      }
    ]
  };
  