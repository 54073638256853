import {
  LuAlignLeft,
  LuAlignRight,
  LuHome,
  LuMessagesSquare,
  LuSettings,
  LuUsers,
  } from "react-icons/lu";
  import { BsClipboard } from "react-icons/bs";
import { Link } from "react-router-dom";

export const Sidebar = ({
  isOpen,
  toggleSidebar,
  setSelectedContent,
  selectedContent,
}) => {
  const getButtonClass = (content) => {
    return `transition-colors duration-200 rounded-lg ${
      selectedContent === content
        ? "text-green-400 bg-stone-900"
        : "text-gray-400 hover:text-gray-300"
    }`;
  };
  return (
    <div

    className={`flex flex-col space-y-24 items-center min-h-screen py-8 bg-stone-900 dark:bg-stone-900 dark:border-stone-700 transition-all duration-300 ${
      isOpen ? "w-16 translate-x-0" : "w-0 -translate-x-3 overflow-visible"
    } md:w-16 md:translate-x-0`}
  >
  
      <button
        onClick={toggleSidebar}
        className={` top-4 text-gray-400 hover:text-gray-300 fixed rounded-sm ${
          isOpen ? "left-1  transition-all duration-300" : "left-5"
        } p-0.5 rounded-xl md:left-4`}
      >
        {isOpen ? (
          <LuAlignRight size={30} className="" />
        ) : (
          <LuAlignLeft size={30} className="" />
        )}
      </button>

      <nav
        className={`flex flex-col items-center flex-1 space-y-8 md:block${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        } transition-opacity duration-300`}
      >
        <button
          onClick={() => {
            setSelectedContent("home");
            if (!isOpen) toggleSidebar();
          }}
          className={getButtonClass("home")}
        >
          <Link to="/home">
            <LuHome size={20} />
          </Link>
        </button>

        <button
          onClick={() => setSelectedContent("messages")}
          className={getButtonClass("messages")}
        >
          <Link to="/history">
            <LuMessagesSquare size={20} />
          </Link>
        </button>

        <button
          onClick={() => setSelectedContent("exam")}
          className={getButtonClass("exam")}
        >
          <Link to="/examsTaken">
          <BsClipboard  size={20} />
          </Link>
        </button>

        <button
          onClick={() => setSelectedContent("users")}
          className={getButtonClass("users")}
        >
          <Link to="/profile">
            <LuUsers size={20} />
          </Link>
        </button>
        
     
      </nav>
    </div>
  );
};