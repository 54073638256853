// mockClassData.js
export const mockClassData = [
    {
      id: 1,
      name: "Primer Grado",
      students: [
        { name: "Juan Pérez", email: "juan@example.com" },
        { name: "María García", email: "maria@example.com" }
      ]
    },
    {
      id: 2,
      name: "Quinto Grado",
      students: [
        { name: "Carlos López", email: "carlos@example.com" },
        { name: "Ana Fernández", email: "ana@example.com" }
      ]
    }
  ];
  