import React from 'react';
import { Link } from 'react-router-dom';

export default function Buttons() {
 
  return (
    <div className="p-6 h-screen rounded-lg shadow-lg">
    <div className='bg-gray-100 text-center font-bold rounded p-4 hover:cursor-pointer hover:bg-gray-300'> <Link to="/teacher">Teacher</Link>
    </div>
    <div className='bg-gray-100 text-center font-bold 
    mt-4 rounded p-4 hover:cursor-pointer hover:bg-gray-300'>
     <Link to="/student">Student</Link>
     </div>
    </div>
  );
}
