import { useState, useEffect, useRef } from "react";
import {
  createAndRunThread,
  getThreadStatus,
  getThreadMessages,
  saveThreadInStrapi,
  createMessage,
  startRun,
} from "../services";
import { parseExam } from "../utils/examParser";

export const useChat = (assistantId, user) => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [threadId, setThreadId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isFirstMessage, setIsFirstMessage] = useState(true);
  const [exam, setExam] = useState(null);
  const messagesEndRef = useRef(null);

  const handleChange = (e) => setInputValue(e.target.value);

  const handleSend = async () => {
    if (!inputValue.trim()) return;

    const userMessage = { text: inputValue, sender: "user", id: Date.now() };
    setMessages((prev) => [...prev, userMessage]);
    setInputValue("");
    setIsLoading(true);
    setIsTyping(true);

    try {
      const response = await sendMessage(userMessage.text);

      const assistantMessage = {
        text: response,
        sender: "assistant",
        id: Date.now() + 1,
      };
      setMessages((prev) => [...prev, assistantMessage]);
      setIsTyping(false);
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
    } finally {
      setIsLoading(false);
      if (isFirstMessage) setIsFirstMessage(false);
    }
  };

  const sendMessage = async (message) => {
    let response;
    try {
      if (threadId) {
        await createMessage(threadId, message);
        await startRun(threadId, assistantId);
        response = await fetchAssistantResponse(threadId);
      } else {
        const res = await createAndRunThread(assistantId, message);
        setThreadId(res.thread_id);
        await saveThreadInStrapi(res.thread_id, res.created_at, user.id);
        response = await fetchAssistantResponse(res.thread_id);
      }

      const examData = parseExam(response);

      if (examData && examData.questions.length > 0) {
        setExam(examData);
        console.log(examData)
      } else {
        console.error("No se detectaron preguntas en el examen.");
      }
    } catch (error) {
      console.error("Error al procesar el mensaje:", error);
    }

    return response;
  };

  const fetchAssistantResponse = async (threadId) => {
    while (true) {
      const statusData = await getThreadStatus(threadId);
      const latestRun = statusData.data[0];

      if (latestRun.status === "completed") {
        const messagesData = await getThreadMessages(threadId);
        return (
          messagesData.data[0]?.content[0]?.text?.value?.trim() ||
          "Sin respuesta."
        );
      }

      if (latestRun.status === "failed") throw new Error("La solicitud falló.");
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return {
    messages,
    inputValue,
    isLoading,
    isTyping,
    isFirstMessage,
    messagesEndRef,
    handleChange,
    handleSend,
    setInputValue,
    setIsFirstMessage,
    exam,
  };
};
