import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import { fetchExamsByUserId } from "../../services/api";
import ExamAccordion from "./ExamAccordion";

export default function AccountExamsTaken() {
  const { user } = useAuthContext();
  const [pendingExams, setPendingExams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Efecto para cargar los exámenes al montar el componente
  useEffect(() => {
    const loadExams = async () => {
      try {
        // Asegúrate de que user.id exista antes de llamar a la API
        if (user?.id) {
          const data = await fetchExamsByUserId(user.id);
          console.log("Exámenes creados por el usuario:", data);
          if (data) {
            // Mapea los datos correctamente
            const userExams = data.map((result) => ({
              id: result.id, // ID del examen
              title: result.attributes.title, // Título del examen
            }));
            setPendingExams(userExams);
          }
        } else {
          setError("Usuario no encontrado.");
        }
      } catch (err) {
        console.error("Error al cargar los exámenes:", err);
        setError("No se pudieron cargar los exámenes creados.");
      } finally {
        setLoading(false);
      }
    };
  
    loadExams();
  }, [user]);
  
  
  
  if (loading) {
    return (
      <div className="p-6 h-screen bg-gray-100 rounded-lg shadow-lg flex items-center justify-center">
        <p className="text-gray-600">Cargando exámenes...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 h-screen bg-gray-100 rounded-lg shadow-lg flex items-center justify-center">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }


  return (
    <div className="p-6 h-screen bg-gray-100 rounded-lg shadow-lg">
      <header className="mb-6">
          <p className="mt-2 text-gray-600">
          Aquí puedes consultar los exámenes completados, sus resultados, y realizar nuevos exámenes.
        </p>
      </header>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Sección de Exámenes Realizados */}
        <div className="p-4 bg-white rounded-lg shadow-md">
          <h2 className="text-lg font-semibold text-gray-700 mb-4">Exámenes realizados</h2>
          <ExamAccordion />
        </div>

        {/* Sección de Exámenes Creados */}
        <div className="p-4 bg-white rounded-lg shadow-md">
          <h2 className="text-lg font-semibold text-gray-700 mb-4">Exámenes creados</h2>
          {pendingExams.length > 0 ? (
          <ul className="text-gray-600">
            {pendingExams.map((exam) => (
              <li
                key={exam.id}
                className="p-3 bg-gray-50 rounded-lg shadow-sm hover:bg-gray-100 transition-all duration-200"
              >
                <Link
                  to={`/exam/${exam.id}`}
                  className="text-blue-600 hover:text-blue-800 font-medium flex justify-between items-center"
                >
                  <span>{exam.title}</span> {/* Aquí se debe mostrar el título del examen */}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500">No tienes exámenes creados.</p>
        )}
        </div>
      </section>
    </div>
  );
}
