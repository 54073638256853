import React, { useState, useEffect } from "react"; 
import { useParams, useNavigate } from "react-router-dom";
import { takeExamById,  submitExamResult } from "../../services/api"; 
import { useAuthContext } from "../../context/AuthContext";

export default function ExamQuestions() {
  const { id } = useParams(); 
  const { user } = useAuthContext();
  const [exam, setExam] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [answers, setAnswers] = useState({});
  const [score, setScore] = useState(0); 
  const [isSubmitted, setIsSubmitted] = useState(false); 
  const [validationError, setValidationError] = useState("");
  const navigate = useNavigate();

  
  // Función para cargar los datos del examen
  useEffect(() => {
    const loadExam = async () => {
      try {
        const data = await takeExamById(id); 
        console.log("data del examen", data);
        setExam(data);
      } catch (err) {
        setError("No se pudo cargar el examen.");
      } finally {
        setLoading(false);
      }
    };

    loadExam();
  }, [id]);

  // Maneja la selección de respuestas
  const handleAnswerChange = (questionId, answer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  };

  // Función para enviar las respuestas del examen
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validación: Verificar que todas las preguntas tengan una respuesta
    const unansweredQuestions = exam?.attributes?.questions?.data.filter(
      (question) => !answers[question.id]
    );
  
    if (unansweredQuestions.length > 0) {
      setValidationError("Por favor, responde todas las preguntas antes de enviar el examen.");
      return;
    }
  
    setValidationError("");
  
    let calculatedScore = 0;
  
    // Iterar sobre todas las preguntas para verificar las respuestas
    exam?.attributes?.questions?.data.forEach((question) => {
      const userAnswer = answers[question.id]; // Respuesta del usuario
      const correctOption = question.attributes.options.data.find(
        (option) => option.attributes.isCorrect // Buscar la opción correcta
      );
  
      // Verificar si la respuesta del usuario coincide con la opción correcta
      if (userAnswer === correctOption?.attributes.text) {
        calculatedScore += 1; // Si es correcta, incrementar el puntaje
      }
    });
  
    // Mostrar el puntaje calculado
    console.log("Puntaje calculado:", calculatedScore);
  
    // Usamos el user del contexto para obtener el userId
    const userId = user?.id;
  
    try {
      const result = await submitExamResult(userId, exam.id, calculatedScore);
      console.log("Resultado enviado:", result);
      setScore(calculatedScore);
      setIsSubmitted(true);
    } catch (error) {
      setError("No se pudo enviar el resultado. Intenta nuevamente.");
    }
  };
  
  
  

  if (loading) {
    return (
      <div className="flex items-center justify-center p-6 h-screen bg-gray-100 rounded-lg shadow-lg">
        <p className="text-gray-600">Cargando preguntas...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center p-6 h-screen bg-gray-100 rounded-lg shadow-lg">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  if (isSubmitted) {
    return (
      <div className="p-6 bg-white rounded-lg shadow-lg max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">Examen completado</h1>
        <p className="text-xl text-gray-700 mb-4">Puntaje: {score} / {exam?.attributes?.questions?.data.length}</p>
        <p className="text-lg text-gray-500">
          {score >= exam?.attributes?.questions?.data.length / 2
            ? "¡Felicidades! Has aprobado el examen."
            : "Lo siento, no has aprobado. Sigue practicando."}
        </p>
        <button
          onClick={() => navigate("/resultado", { state: { exam, answers } })}
          className="w-full mt-6 bg-blue-500 text-white py-3 px-6 rounded-lg shadow-md hover:bg-blue-600 transition-colors"
        >
          Ver resultados detallados
        </button>
      </div>
    );
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">{exam?.attributes?.title}</h1>
      <form onSubmit={handleSubmit}>
        {exam?.attributes?.questions?.data.map((question) => (
          <div key={question.id} className="mb-8">
            <h2 className="text-2xl font-semibold text-blue-700 mb-4">
              {question.attributes.questionText}
            </h2>
            <div className="space-y-4">
              {question.attributes.options.data.map((option) => (
                <label
                  key={option.id}
                  className="flex items-center space-x-3 p-3 bg-gray-100 rounded-lg shadow-sm hover:bg-gray-200 cursor-pointer transition-all"
                >
                  <input
                    type="radio"
                    name={`question-${question.id}`}
                    value={option.attributes.text}
                    onChange={() => handleAnswerChange(question.id, option.attributes.text)}
                    className="form-radio text-blue-500"
                  />
                  <span className="text-gray-700 text-lg">{option.attributes.text}</span>
                </label>
              ))}
            </div>
          </div>
        ))}

        {validationError && (
          <div className="mt-4 p-4 bg-red-100 text-red-600 rounded-lg">
            {validationError}
          </div>
        )}

        <button
          type="submit"
          className="w-full mt-6 bg-blue-500 text-white py-3 px-6 rounded-lg shadow-md hover:bg-blue-600 transition-colors"
        >
          Enviar respuestas
        </button>
      </form>
    </div>
  );
}