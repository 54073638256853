import React from 'react';
import { useLocation } from 'react-router-dom';

export default function ResultDetails() {
  const location = useLocation();
  const { exam, answers } = location.state;

  return (
    <div className="p-8 bg-gray-50 rounded-lg shadow-xl max-w-4xl mx-auto">
      <h1 className="text-4xl font-semibold text-gray-800 mb-8 text-center">Resultados Detallados</h1>
      
      {exam?.attributes?.questions?.data.map((question) => {
        const userAnswer = answers[question.id];
        const correctOption = question.attributes.options.data.find(
          (option) => option.attributes.isCorrect
        );
        const isCorrect = userAnswer === correctOption?.attributes.text;

        return (
          <div key={question.id} className="mb-8 p-6 bg-white rounded-lg shadow-md border-l-4 border-blue-500">
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">{question.attributes.questionText}</h2>
            
            <div className="mb-4">
              <p className="text-lg text-gray-600">
                <strong>Respuesta seleccionada:</strong>
                <span className={`ml-2 ${isCorrect ? 'text-green-600' : 'text-gray-600'}`}>
                  {userAnswer ? userAnswer : "No respondida"}
                </span>
              </p>
            </div>
            
            <div className="mb-4">
              <p className="text-lg text-gray-600">
                <strong>Respuesta correcta:</strong>
                <span className="ml-2 text-blue-500">{correctOption ? correctOption.attributes.text : "No disponible"}</span>
              </p>
            </div>
            
            <div>
              {isCorrect ? (
                <p className="text-green-500 font-semibold">✔️ Respuesta correcta</p>
              ) : (
                <p className="text-red-500 font-semibold">❌ Respuesta incorrecta</p>
              )}
            </div>
          </div>
        );
      })}

      <div className="mt-8">
        <a 
          href="/examsTaken"
          className="text-lg font-medium text-blue-600 hover:text-blue-800 underline"
        >
          Volver a los exámenes
        </a>
      </div>
    </div>
  );
}
